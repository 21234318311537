.fab-wrapper {
  position: fixed;
  bottom: 15rem;
  right: 4rem;
  z-index: 999;
}

.fab-checkbox {
  display: none;
}
.fab {
  position: absolute;
  bottom: 2.2rem;
  right: -1rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  z-index: 1;
  stroke-width: 1;
  stroke: white;
  filter: drop-shadow(0.364px 1.457px 2.675px rgba(0, 0, 0, 0.5));
  cursor: pointer;
  background-color: black;
  background-image: url("/assets/images/logos/btn-logo.png");
  background-size: 100% 100%;
  transition: all 0.3s ease;
}
.fab:hover {
  scale: 1.05;
}

.fab-checkbox:checked ~ .fab:before {
  width: 90%;
  height: 90%;
  left: 5%;
  top: 5%;
  background-color: rgba(255, 255, 255, 0.2);
}

.fab-dots {
  position: absolute;
  height: 8px;
  width: 8px;
  background-color: white;
  border-radius: 50%;
  top: 50%;
  transform: translateX(0%) translateY(-50%) rotate(0deg);
  opacity: 1;
  animation: blink 3s ease infinite;
  transition: all 0.3s ease;
  visibility: hidden;
}

.fab-checkbox:checked ~ .fab {
  transition: all 0.3s ease;
  background-image: none;
  background-color: black;
}

.fab-checkbox:checked ~ .fab .fab-dots {
  visibility: visible;
}

.fab-dots-1 {
  left: 15px;
  animation-delay: 0s;
}
.fab-dots-2 {
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  animation-delay: 0.4s;
}
.fab-dots-3 {
  right: 15px;
  animation-delay: 0.8s;
}

.fab-checkbox:checked ~ .fab .fab-dots {
  height: 4px;
}

.fab .fab-dots-2 {
  transform: translateX(-50%) translateY(-50%) rotate(0deg);
}

.fab-checkbox:checked ~ .fab .fab-dots-1 {
  width: 36px;
  border-radius: 10px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}
.fab-checkbox:checked ~ .fab .fab-dots-3 {
  width: 36px;
  border-radius: 10px;
  right: 50%;
  transform: translateX(50%) translateY(-50%) rotate(-45deg);
}

@keyframes blink {
  50% {
    opacity: 0.25;
  }
}

.fab-checkbox:checked ~ .fab .fab-dots {
  animation: none;
}

.fab-wheel {
  position: absolute;
  bottom: -100px;
  right: 0;
  height: 30rem;
  transition: all 0.3s ease;
  transform-origin: center right;
  transform: scale(0);
}

.fab-checkbox:checked ~ .fab-wheel {
  transform: scale(1);
}
.fab-action {
  position: absolute;
  background: white;
  width: 7rem;
  height: 7rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: White;
  filter: drop-shadow(0.85px 6.26px 3.41px rgba(0, 0, 0, 0.5));
  transition: all 1s ease;
  opacity: 1;
  cursor: pointer;
  padding: 1rem;
}

.fab-action .logo {
  width: 100%;
  height: 100%;
  background-size: 100% 100%;
}

.fab-action .zalo {
  background-image: url("/assets/images/zalo.png");
}

.fab-action .messenger {
  background-image: url("/assets/images/messenger.png");
}

.fab-action .phone {
  background-image: url("/assets/images/phone.png");
}

.fab-action .location {
  background-image: url("/assets/images/location.png");
}

.fab-checkbox:checked ~ .fab-wheel .fab-action {
  opacity: 1;
}

.fab-action:hover {
  background: #f1f1f1;
}

.fab-wheel .fab-action-1 {
  right: -1rem;
  top: 0;
}

.fab-wheel .fab-action-2 {
  right: 7rem;
  top: 6.6rem;
}
.fab-wheel .fab-action-3 {
  right: 7rem;
  bottom: 7rem;
}
.fab-wheel .fab-action-4 {
  right: -1rem;
  bottom: 0.8rem;
}

@media (max-width: 768px) {
  .fab-wrapper {
    bottom: 10rem;
    right: 2rem;
  }
}
